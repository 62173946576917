<template>
  <div>
    <p>品牌产品列表</p>
    <el-table v-loading="loading" class="list-style" :data="tableData" border style="width: 100%">
      <el-table-column
        fixed
        type="index"
        label="序号"
        :index="initIndex"
        align="center"
        width="100"
      />
      <el-table-column align="center" prop="productId" label="id" />
      <el-table-column align="center" prop="subject" label="名称" />
      <el-table-column align="center" prop="image" label="缩略图">
        <template slot-scope="scope">
          <img
            style="width: 100px; height: 100px"
            :src="scope.row.image"
            alt="暂无图片"
            srcset=""
          >
        </template>
      </el-table-column>>
      <el-table-column align="center" prop="wholesalePrice" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="details(scope.row)">
            查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="page.current"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="page.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { getBrandProductList } from '@/api/register'
import Pagination from './pagination.js'
export default {
  name: 'BrandProductList',
  mixins: [Pagination],
  data() {
    return {
      tableData: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      getBrandProductList(this.page)
        .then((result) => {
          if (result.code === 0) {
            this.loading = false
            this.tableData = result.data.records
            this.page.total = result.data.total
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    details(row) {
      console.log(row, '行')
    }
  }
}
</script>

<style lang="scss" scoped>
  .list-style{
    margin: 10px 0;
    min-width: 1200px;
  }
</style>
