<template>
  <div style="min-height: 100vh;">
    <div class="main">
      <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
        <el-tab-pane name="1" label="1688产品">
          <AlibabaProductList />
        </el-tab-pane>
        <el-tab-pane name="2" label="品牌产品">
          <BrandProductList />
        </el-tab-pane>
        <el-tab-pane name="3" label="用户产品">
          <UserProductList />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import BrandProductList from './selectionlistcomponent/brandProductList'
import AlibabaProductList from './selectionlistcomponent/alibabaProductList'
import UserProductList from './selectionlistcomponent/userProductList'

export default {
  components: {
    BrandProductList,
    AlibabaProductList,
    UserProductList
  },
  data() {
    return {
      activeName: '1'
    }
  },
  watch: {
  },
  created() {
  },
  destroyed() {
  },
  mounted: function() {
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  max-width: 1920px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
}
</style>
